import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { SharedDataService } from 'orv-ui-base-lib';
import { Staff } from 'orv-ui-base-lib';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthenticationGuardService implements CanActivate, CanActivateChild {

  loggedUser: Staff;

  constructor(private sharedDataService: SharedDataService,
    private router: Router,
    private authService: AuthService) {
    this.sharedDataService.currentLoggedUser.subscribe(loggedUser => {
      this.loggedUser = loggedUser;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.loggedUser) {
        if (this.checkCommercialFeature(route)) {
            return of(true);
        } else {
            return of(false);
        }
    } else {
      if (this.authService.hasLocalStorage()) {
        return Observable.create(observer => {
          this.authService.getLoggedUserLite().subscribe(() => {
            observer.next(true);
            observer.complete();
          }, () => {
            observer.next(false);
            observer.complete();
          });
        });
      } else {
        this.router.navigate(['login'], {});
        return of(false);
      }
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
      if (this.checkCommercialFeature(route)) {
          return of(true);
      } else {
          return of(false);
      }
  }

  checkCommercialFeature(route: ActivatedRouteSnapshot) {
      if (!route.data.commercial) {
          return true;
      } else {
          const isCommercial = route.data.commercial;
          if (isCommercial === environment.commercial) {
              return true;
          }
      }
      return false;
  }

}
