import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AttachService, MessageService } from 'orv-ui-base-lib';

@Component({
    selector: 'app-attachment-upload',
    templateUrl: './attachment-upload.component.html',
    styleUrls: ['./attachment-upload.component.scss']
})
export class AttachmentUploadComponent implements OnInit {

    @Input() entity: string;
    @Input() eid: number;

    // tslint:disable-next-line: no-output-on-prefix
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @Output() uploadSuccess: EventEmitter<any> = new EventEmitter();

    filename: any;
    file: any;
    loading: boolean = false;
    progress: number;
    @Input() limitToSize: { maxwidth: number, maxheight: number, minwidth: number, minheight: number };
    @Input() insideForm: boolean = false;

    constructor(private attachService: AttachService, private messageService: MessageService,
        private translateService: TranslateService) { }

        ngOnInit() {
        }

        onChange(event: any) {

            const files = event.target.files || event.dataTransfer.files;
            this.file = files && files.length ? files[0] : null;

            if (this.file && this.limitToSize) {
                const img = new Image();

                img.src = window.URL.createObjectURL(this.file);

                img.onload = () => {
                    const width = img.naturalWidth,
                    height = img.naturalHeight;

                    if ((this.limitToSize.maxwidth && width > this.limitToSize.maxwidth) ||
                         (this.limitToSize.maxheight && height > this.limitToSize.maxheight)) {
                        this.reset();
                        this.messageService.sendError(this.translateService.instant('labels.error-logo-size') +
                            ': ' + this.limitToSize.maxwidth + 'x' + this.limitToSize.maxheight);

                    } else if ((this.limitToSize.minwidth && width < this.limitToSize.minwidth) ||
                         (this.limitToSize.minheight && height < this.limitToSize.minheight)) {
                        this.reset();
                        this.messageService.sendError(this.translateService.instant('labels.error-logo-min-size') +
                            ': ' + this.limitToSize.minwidth + 'x' + this.limitToSize.minheight);

                    } else {
                        if (this.insideForm) {
                            this.save();
                        }
                    }


                };
            } else {

                if (this.insideForm && this.file) {
                    this.save();
                }
            }
        }

        reset() {
            this.filename = undefined;
            this.file = undefined;
        }

        save() {
            this.loading = true;
            const events = this.attachService.upload(this.entity, this.eid, this.file);

            events.progress.subscribe(response => {
                this.progress = Math.round(response.loaded * 100 / response.total);

            });

            events.success.subscribe(result => {
                this.loading = false;
                this.uploadSuccess.emit(result);
                if (!this.insideForm) {
                    this.messageService.sendSuccess(this.translateService.instant('labels.attach-save-success'),
                    this.translateService.instant('labels.attach'));
                }
            });

            this.onSave.emit(events);
        }

    }
