import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { OrvServicesModule } from 'orv-ui-base-lib';

import { CoreComponentsModule } from './core-components/core-components.module';
import { DataModule } from './data/data.module';
import { throwIfAlreadyLoaded } from './module-import-guard';

const NB_CORE_PROVIDERS = [
    ...OrvServicesModule.forRoot().providers,
    ...DataModule.forRoot().providers,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        CoreComponentsModule
    ],
    declarations: [],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders {
        return <ModuleWithProviders>{
            ngModule: CoreModule,
            providers: [
                ...NB_CORE_PROVIDERS,
            ],
        };
    }
}
