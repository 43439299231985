import { Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TokenLoginComponent } from './token-login/token-login.component';

export const AuthenticationRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'auth/:token',
                component: TokenLoginComponent,
            },
            {
                path: 'logout',
                component: LogoutComponent
            },
            {
                path: 'resetpassword/:token',
                component: ResetPasswordComponent
            }
        ]
    }
];
