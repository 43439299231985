import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'orv-ui-base-lib';
import { Md5 } from 'ts-md5';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    password: string;
    passwordcheck: string;
    token: string;
    resetInfo: any;
    checkerror = false;
    passwordError = false;

    constructor(private authService: AuthService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private router: Router) {
            this.route.params.subscribe(p => {
                this.token = p['token'];
                if (this.token) {
                    this.checkToken();
                }
            });
         }

    ngOnInit() {
    }

    checkToken() {
        this.authService.resetPasswordCheck(this.token).subscribe(result => {
           this.resetInfo = result;
        }, error => {
            this.checkerror = true;
        });
    }

    submit() {
        if (!this.password || this.password === '' || this.password !== this.passwordcheck) {
            this.passwordError = true;
        } else {
            this.passwordError = false;
            this.resetInfo.password = Md5.hashAsciiStr(this.password);
            this.authService.resetPasswordApply(this.resetInfo).subscribe(result => {
                this.messageService.sendSuccess('Success', 'Reset Password');
                this.router.navigateByUrl('/login');
            }, error => {
                this.messageService.sendError(error.error.msg);
            });
        }
    }
}
