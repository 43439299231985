import { Component, Input, OnDestroy } from '@angular/core';
import { CustomerAccess, SharedDataService } from 'orv-ui-base-lib';
import { Subscription } from 'rxjs';
import { NavMenu } from 'src/app/@core/navMenu.interface';

@Component({
    // tslint:disable-next-line: component-selector
    selector: '[navbar-mobile]',
    templateUrl: './navbar-mobile.component.html',
    styleUrls: ['./navbar-mobile.component.scss']
})
export class NavbarMobileComponent implements OnDestroy {

    @Input() alerts: number;

    openMenu: boolean = false;
    loggeduser: CustomerAccess;

    navMenu: NavMenu[];

    subscription: Subscription;

    constructor(
        private sharedDataService: SharedDataService,
    ) {
        this.subscription = this.sharedDataService.currentLoggedUser.subscribe(user => {
            this.loggeduser = user;

            // const canShowOrvconnect: boolean = this.loggeduser.catype == 'parent' && this.loggeduser.billingcustid !== null && this.loggeduser.ecomm === 1;

            this.navMenu = [
                // { label: 'dashboard', url: '/dashboard', icon: 'far fa-chart-bar', faicon: true, show: true },
                { label: 'deliveries', url: '/deliveries/deliveries-history', icon: 'icon icon-deliveries-icon', faicon: false, show: true },
                // { label: 'onroute-connect', url: '/onroute-connect/list', icon: 'fas fa-globe', faicon: true, show: true },
                { label: 'open-ticket', url: '/ticket/new-edit', icon: 'icon icon-opentiket-icon', faicon: false, show: true },
                { label: 'my-tickets', url: '/ticket/list', icon: 'icon icon-mytickets-icon', faicon: false, show: true },
                // { label: 'access-management', url: '/children', icon: 'icon icon-users', faicon: false, show: canShowOrvconnect },
                // { label: 'reports', url: '/reporting', icon: 'far fa-list-alt', faicon: true, show: true },
                // { label: 'profile', url: '/profile', icon: 'icon icon-profile-icon', faicon: false, show: true },
                // { label: 'notifications', url: '/message/list', icon: 'icon icon-alerts-icon', faicon: false, show: true, alerts: true },
                // { label: 'about', url: '/about', icon: 'icon icon-about-icon', faicon: false, show: true },
                { label: 'logout', url: '/logout', icon: 'icon icon-logout-icon', faicon: false, show: true },
            ];
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) { this.subscription.unsubscribe(); }
    }

}
