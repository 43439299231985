import 'rxjs/add/observable/of';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import { CookieService } from 'ngx-cookie-service';
import { CustomerAccess, CustomerAccessService, Login, SharedDataService } from 'orv-ui-base-lib';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/internal/operators/map';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';

@Injectable()
export class AuthService {

    token: string;
    tokenCookie = 'ORVCustomerToken';
    userCookie = 'ORVCustomerUser';

    constructor(protected http: HttpClient,
        private cookieService: CookieService,
        private sharedDataService: SharedDataService,
        private customerAccessService: CustomerAccessService) {

        sharedDataService.currentToken.subscribe(token => { this.token = token; });
    }

    login(login: Login, tokenparam?: string) {
        return this.http.get('/ws/jwt/token', {}).pipe(mergeMap((token: any) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Bearer: ' + token.jwt
                }),
            };
            return this.http.post(`/ws/customerapp/login${tokenparam ? '?uitoken=' + tokenparam : ''}`,
                classToPlain(login), httpOptions).pipe(map(r => {
                    const user: CustomerAccess = plainToClass(CustomerAccess, r as Object);
                    // this.cookieService.set(this.tokenCookie, user.jwt);
                    // this.cookieService.set(this.userCookie, user.id + '');
                    localStorage.setItem(this.tokenCookie, user.jwt);
                    localStorage.setItem(this.userCookie, user.id + '');
                    this.sharedDataService.setLoggedUser(user);
                    return user;
                }));


        }));
    }

    logout() {
        // this.cookieService.delete(this.tokenCookie);
        // this.cookieService.delete(this.userCookie);
        localStorage.removeItem(this.userCookie);
        localStorage.removeItem(this.tokenCookie);
        this.sharedDataService.setLoggedUser(undefined);
    }

    renew() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer: ' + this.getToken()
            }),
        };
        return this.http.get('/ws/jwt/carenew', httpOptions).pipe(map((token: any) => {
            // this.cookieService.set(this.tokenCookie, token.jwt);
            localStorage.setItem(this.tokenCookie, token.jwt);
        }));
    }

    hasCookie() {
        // return this.cookieService.get(this.tokenCookie) && this.cookieService.get(this.userCookie);
    }

    hasLocalStorage() {
        return localStorage.getItem(this.tokenCookie) && localStorage.getItem(this.userCookie);
    }

    getToken() {
        // return this.cookieService.get(this.tokenCookie);
        return localStorage.getItem(this.tokenCookie);
    }

    forgotPwd(email: string) {
        return this.http.get('/ws/jwt/token', {}).pipe(mergeMap((token: any) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Bearer: ' + token.jwt
                }),
            };
            return this.http.post('/ws/customerapp/forgotpsw', { email }, httpOptions);
        }));
    }

    resetPasswordCheck(tkn: string) {
        return this.http.get('/ws/jwt/token', {}).pipe(mergeMap((token: any) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Bearer: ' + token.jwt
                }),
            };
            return this.http.get('/ws/resetpassword/resetform/' + tkn, httpOptions);
        }));
    }

    resetPasswordApply(info: any) {
        return this.http.get('/ws/jwt/token', {}).pipe(mergeMap((token: any) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Bearer: ' + token.jwt
                }),
            };
            return this.http.post('/ws/resetpassword/apply', info, httpOptions);
        }));
    }

    resetLoggedPwd(info: any) {
        return this.http.post('/ws/customerapp/changepsw', info);
    }

    /*
    getLoggedUser() {
        // const token = this.cookieService.get(this.tokenCookie);
        // const user = this.cookieService.get(this.userCookie);
        const token = localStorage.getItem(this.tokenCookie);
        const user = localStorage.getItem(this.userCookie);
        this.sharedDataService.setToken(token);
        if (token && user) {
            return this.getUser(user).pipe(map(data => {
                this.token = data.jwt;
                this.sharedDataService.setLoggedUser(data);
                return data;
            }));
        }
        return of(undefined);
    }
    */

    getLoggedUserLite() {
        // const token = this.cookieService.get(this.tokenCookie);
        // const user = this.cookieService.get(this.userCookie);
        const token = localStorage.getItem(this.tokenCookie);
        const user = localStorage.getItem(this.userCookie);
        this.sharedDataService.setToken(token);
        if (token && user) {
            return this.getUserLite(user).pipe(map(data => {
                this.token = data.jwt;
                this.sharedDataService.setLoggedUser(data);
                return data;
            }));
        }
        return of(undefined);
    }

    /*
    getUser(user: string): Observable<any> {
        return this.customerAccessService.getProfile(+user);
    }
    */

    getUserLite(user: string): Observable<any> {
        return this.customerAccessService.getProfileLite(+user);
    }

}

