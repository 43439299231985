import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable()

export class JwtInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService, private router: Router) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, (err: any) => {
            console.log('intercept error', err);
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                     this.auth.logout();
                     this.router.navigate(['login'], {});
                }
                if (err.status === 403) {
                        console.log('token expired');
                 }
            }
        }));
    }
}
