import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AttachService } from 'orv-ui-base-lib';

@Component({
  selector: 'app-attachment-image-modal',
  templateUrl: './attachment-image-modal.component.html',
  styleUrls: ['./attachment-image-modal.component.scss']
})
export class AttachmentImageModalComponent implements OnInit, OnDestroy, OnChanges {

  @Input() id: number;
  @Input() isModal = true;
  @Input() inputstyle: string;

  @Output() attachDowloaded: EventEmitter<any> = new EventEmitter();

  file: any;

  loading: boolean = false;
  url: any;

  constructor(private modal: NgbActiveModal,
    private attachService: AttachService) { }


    ngOnChanges(changes: SimpleChanges): void {
        if (this.id) {
            this.downloadAttach();
        }
    }

  ngOnInit() {
   if(this.id) {
       this.downloadAttach();
   }
  }

  ngOnDestroy() {
    if (this.url) URL.revokeObjectURL(this.url);
  }

  downloadAttach() {
     this.loading = true;
    this.attachService.download(this.id).subscribe(response => {
      this.file = response;
      if (response) {
        this.url = URL.createObjectURL(response);
        this.attachDowloaded.emit(this.url);
      }
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  close() {
    this.modal.dismiss('close');
  }

}
