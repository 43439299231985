import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedDataService } from 'orv-ui-base-lib';

import { ResponsiveCommon } from '../../responsive.common';

@Component({
    selector: 'app-logo-header',
    templateUrl: './logo-header.component.html',
    styleUrls: ['./logo-header.component.scss']
})
export class LogoHeaderComponent extends ResponsiveCommon implements OnInit {

    @Input() title: string;
    @Input() subtitle: string;
    bannerurl: string = '/assets/images/title-background.png';
    background: any;

    constructor(private sharedDataService: SharedDataService,
        private sanitizer: DomSanitizer) {
        super();
        this.background = this.sanitizer.bypassSecurityTrustStyle(`url(${this.bannerurl})`);
        this.sharedDataService.currentCommondata.subscribe(data => {
            if (data && data.logobanner) {
                this.background = this.sanitizer.bypassSecurityTrustStyle(`url(${data.logobanner})`);
                console.log(this.background);
            }
        });
    }

    ngOnInit() {
        if (this.responsive && this.title.includes(':')) {
            const splitted = this.title.split(':');
            this.title = splitted[0];
            this.subtitle = splitted[1];
        }
    }

}
