import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AttachService, Login, MessageService, SharedDataService } from 'orv-ui-base-lib';
import { Subscription } from 'rxjs';

import { ResponsiveCommon } from '../../responsive.common';
import { AuthService } from '../auth.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends ResponsiveCommon implements OnInit, OnDestroy {

    logindata: Login = new Login();
    message: string;

    subscription: Subscription;
    forgotpwd = false;
    email: string;

    constructor(private authService: AuthService,
        private sharedDataService: SharedDataService,
        private attachService: AttachService,
        private messageService: MessageService,
        private router: Router) {
        super();
    }

    ngOnInit() {
        this.subscription = this.sharedDataService.currentLoggedUser.subscribe(u => {
            if (u) {
                if (this.responsive) { this.router.navigateByUrl('/deliveries/deliveries-history'); }
                else { this.router.navigateByUrl('/'); }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    forgot() {
        this.authService.forgotPwd(this.email).subscribe(result => {
            this.email = '';
            this.forgotpwd = false;
            this.messageService.sendSuccess('Password reset request sent');
        }, error => {
            this.messageService.sendError(error.error.msg);
        });
    }


    login() {
        this.authService.login(this.logindata).subscribe(data => {
            if (!data) {
                this.message = 'Login failed';
            }
        }, (error) => {
            console.log('ERROR:', error);
            this.message = 'Login failed';
        });
    }

}
