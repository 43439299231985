import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../auth.service';

@Component({
    selector: 'app-token-login',
    templateUrl: './token-login.component.html',
    styleUrls: ['./token-login.component.scss']
})
export class TokenLoginComponent {

    uitoken: string;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.route.params.subscribe(p => {
            this.uitoken = p['token'];
            if (this.uitoken) {
                this.loginWithToken();
            } else {
                this.router.navigateByUrl('/login');
            }
        });
    }

    loginWithToken() {
        this.authService.login(undefined, this.uitoken).subscribe(() => {
            this.router.navigateByUrl('/login');
        }, (error) => {
            console.log('ERROR:', error);
            this.router.navigateByUrl('/login');
        });
    }

}
