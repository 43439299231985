import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuardService } from './@core/auth/auth-guard.service';
import { LayoutComponent } from './layout/layout.component';
import { TocComponent } from './toc/toc.component';

export const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            {
                path: 'dashboard',
                loadChildren: './dashboard/dashboard.module#DashboardModule'
            },
            {
                path: 'deliveries',
                loadChildren: './deliveries/deliveries.module#DeliveriesModule'
            },
            {
                path: 'onroute-connect',
                loadChildren: './orvconnect/orvconnect.module#OrvconnectModule',
            },
            {
                path: 'customer-feedback',
                loadChildren: './customer-feedback/customer-feedback.module#CustomerFeedbackModule',
            },
            {
                path: 'ticket',
                loadChildren: './ticket/ticket.module#TicketModule'
            },
            {
                path: 'message',
                loadChildren: './message/message.module#MessageModule'
            },
            {
                path: 'about',
                loadChildren: './about/about.module#AboutModule'
            },
            {
                path: 'profile',
                loadChildren: './profile/profile.module#ProfileModule'
            },
            {
                path: 'children',
                loadChildren: './children/children.module#ChildrenModule'
            },
            {
                path: 'reporting',
                loadChildren: './reporting/reporting.module#ReportingModule'
            }
        ],
        canActivate: [AuthenticationGuardService],
        canActivateChild: [AuthenticationGuardService]
    },
    {
        path: 'toc',
        component: TocComponent,
    },
    {
        path: 'customersurvey/:opqid',
        loadChildren: './customersurvey/customersurvey.module#CustomerSurveyModule'
    },
    {
        path: 'orvconnect/feedback/:code',
        loadChildren: './orvconnect-feedback/orvconnect-feedback.module#OrvconnectFeedbackModule'
    },
    {
        path: 'deliveryinfo/:code',
        data: {
            title: 'Delivery Info'
        },
        loadChildren: './delivery-info/delivery-info.module#DeliveryInfoModule'
    },
    {
        path: 'orvconnect/time/:code',
        data: {
            title: 'Orvconnect Delivery confirmation'
        },
        loadChildren: './orvconnect-delivery-confirmation/orvconnect-delivery-confirmation.module#OrvconnectDeliveryConfirmationModule'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
