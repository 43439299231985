import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'orv-ui-base-lib';

@Injectable()
export class MessagingService {

    currentMessage = new BehaviorSubject(null);

    constructor(
        private angularFireDB: AngularFireDatabase,
        private angularFireAuth: AngularFireAuth,
        private angularFireMessaging: AngularFireMessaging) {

            this.angularFireMessaging.messaging.subscribe(
            (_messaging) => {
                _messaging.usePublicVapidKey('BF5rL3DHUzYeyfa1AqmYXyceolEFxtVT4PGBYekrvy47LNfC1mOJje66vRldLeZyyAGzUoCzXFgv9ZNiToDACT0');

                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        );

    }

    /**
     * update token in firebase database
     *
     * @param userId userId as a key
     * @param token token as a value
     */
    updateToken(userId, token) {
        // we can change this function to request our backend service
        this.angularFireAuth.authState.pipe(take(1)).subscribe(
            () => {
                const data = {};
                data[userId] = token;
                this.angularFireDB.object('fcmTokens/').update(data);
            });
    }

    /**
     * request permission for notification from firebase cloud messaging
     *
     * @param user user
     */
    requestPermission(user: User): Observable<any> {
        return this.angularFireMessaging.requestToken;
    }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                console.log('new message received. ', payload);
                this.currentMessage.next(payload);
            });
    }
}
