import { Component, Injector, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData, DOCUMENT } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { environment } from '../environments/environment';
import { MessagingService } from './@core/data/messaging.service';
import { SharedDataService } from 'orv-ui-base-lib';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})


export class AppComponent {

    message: any;

    constructor(private translateService: TranslateService,
        private messagingSvc: AngularFireMessaging,
        private readonly titleService: Title,
        private router: Router,
        private messaggingService: MessagingService,
        private sharedDataService: SharedDataService,

        @Inject(DOCUMENT) private _document: HTMLDocument,
        private injector: Injector) {
        this.translateService.addLangs(['en']);
        this.translateService.setDefaultLang('en');
        this.translateService.use('en');
        // register locale for pipe
        registerLocaleData(localeEn, 'en');

        if (!environment.commercial) {
            this._document.getElementById('appFavicon').setAttribute('href', 'assets/images/favicon.png');
        }

        this.router.events.subscribe(route => {
            if (route instanceof NavigationEnd) {
                if (this.router.routerState.root.firstChild) {
                    this.router.routerState.root.firstChild.data.subscribe(item => {
                        const title = item.title;
                        if (title !== '' && title) {
                            this.titleService.setTitle(title);
                        }
                    });
                }
            }
        });


        this.sharedDataService.loggedUser.subscribe(user => {
            if (user) {
                this.messaggingService.requestPermission(user.id).subscribe(
                    (token) => {
                        console.log('token', token);
                        this.messaggingService.receiveMessage();
                        const message = this.messaggingService.currentMessage;
                        message.subscribe(mess => {
                            console.log('mess', mess);
                        });
                    },
                    (err) => {
                        console.error('Unable to get permission to notify.', err);
                    }
                );
            }
        });




    }




}
