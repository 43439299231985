import { CookieService } from 'ngx-cookie-service';

import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent implements OnInit {

  email: string;
  password: string;
  message: string;

  constructor(private cookieService: CookieService,
    private router: Router,
    private authService: AuthService) { 


        this.authService.logout();
        this.router.navigate(['login'], {});
    }

  ngOnInit() {
  }


}
