import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ParamsService, Search } from 'orv-ui-base-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponsiveCommon } from '../../responsive.common';

@Component({
    selector: 'app-list-filters',
    templateUrl: './list-filters.component.html',
    styleUrls: ['./list-filters.component.scss']
})
export class ListFiltersComponent extends ResponsiveCommon implements OnInit, OnDestroy {


    @Input()
    searchparams: Search;

    currentquery: any;

    @Output() onSearch: EventEmitter<Search> = new EventEmitter<Search>();

    showAdvancedSearch: boolean = false;

    autocompleteMinChars: number = 4;

    constructor(protected logger: NGXLogger, protected router: Router,
        protected _route: ActivatedRoute,
        protected paramsService: ParamsService) {
        super();
    }

    ngOnInit() {
    }
    ngOnDestroy() {
    }


    readParams(): Observable<any> {
        this.logger.log('super init');
        return this._route.queryParams.pipe(map(params => {
            if (!this.currentquery) {
                this.currentquery = params;
                if (params && params.s) {
                    this.readQueryParams(params.s);
                }
                if (params && params.adv !== undefined) {
                    setTimeout(() => this.showAdvancedSearch = params.adv === 'true');
                }
                return this.searchparams;
            }
        }));
    }

    resetSearch() {
        this.logger.log('resetSearch');
        this.searchparams.reset();
        this.search(true);
    }

    search(keeppage?: boolean) {
        this.logger.log('search base');


        if (!keeppage) {
            console.log('setting page no');
            this.searchparams.page.setPageNumber(0);
        }

        console.log('search params:', this.searchparams);

        if (this.searchparams) {
            this.addQueryParams();
            this.onSearch.emit(this.searchparams);
        }
    }

    protected readQueryParams(params: string) {
        this.searchparams.setDataFromParams(params);
    }

    addQueryParams() {
        this.logger.log('addQueryParams');

        const params = this.searchparams.getQueryParams();
        console.log('params:', params);
        if (params) {
            params['adv'] = this.showAdvancedSearch;

            this.paramsService.addQueryParams(params);

        }
    }

    toggleAdvanced() {
        this.showAdvancedSearch = !this.showAdvancedSearch;
        if (!this.showAdvancedSearch) { // if I close it, I want to keep it closed
            this.addQueryParams();
        }

    }

}
