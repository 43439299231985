import { Directive, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
    selector: '[commFeature]'
})
export class CommercialFeatureDirective {

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) { }

    @Input()
	set commFeature(condition: boolean) {
		if (
			(
				environment.hasOwnProperty('commercial') &&
				environment.commercial === true &&
				condition === true
			) || (
				environment.hasOwnProperty('commercial') &&
				environment.commercial === false &&
				condition === false
			)
		) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else if (
			environment.hasOwnProperty('commercial') &&
			environment.commercial === true &&
			condition === false
		) {
			this.viewContainer.clear();
		}
	}

}
