import { AgmCoreModule } from '@agm/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { AuthModule } from './@core/auth/auth.module';
import { CoreModule } from './@core/core.module';
import { ServiceLocator } from './@core/data/locator.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { NavbarMobileComponent } from './layout/navbar-mobile/navbar-mobile.component';
import { TocComponent } from './toc/toc.component';


export function HttpLoaderFactory(http: HttpClient) {
    if (environment.hasOwnProperty('commercial') && environment.commercial === true) {
        return new TranslateHttpLoader(http, undefined, '-commercial.json');
    }
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        TocComponent,
        NavbarMobileComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule.forRoot(),
        HttpClientModule,
        ToastrModule.forRoot(),
        AuthModule,
        FormsModule,
        NgxPaginationModule,
        NgxCleaveDirectiveModule,
        NgbModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyA9YsRv2pFxoUzcP8jaBtAOIbDyjZl-q6I',
            libraries: ['drawing', 'places', 'geometry', 'visualization']
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        LoggerModule.forRoot({
            level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
            // serverLogLevel
            serverLogLevel: NgxLoggerLevel.OFF
        }),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        DeviceDetectorModule.forRoot(),
    ],
    providers: [TranslateService, { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {
        // Create global Service Injector.
        ServiceLocator.injector = this.injector;
    }
}
