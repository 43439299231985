import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { orderBy, cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Counter } from 'orv-ui-base-lib';
/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

@Component({
    selector: 'app-pie-chart',
    templateUrl: './pie-chart.component.html',
    styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input() datas: Counter[];
    @Input() id: string;
    @Input() limit: number = 100;
    @Input() height: number = 200;

    chart: any;
    ordereddata: any;
    total: number = 0;
    more: boolean = false;
    viewInitialized = false;

    constructor(private translateService: TranslateService) { }

    ngOnInit() { }

    ngOnDestroy(): void {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.datas && this.datas.length && this.viewInitialized) {
            this.initChart();
        }
    }

    ngAfterViewInit() {
        this.viewInitialized = true;
        if (this.datas && this.datas.length) {
            this.initChart();
        }
    }

    initChart() {
        setTimeout(() => this.createChart());
    }

    createChart() {
        if (this.chart) { this.chart.dispose(); }
        this.chart = am4core.create(this.id, am4charts.PieChart);

        const pieSeries = this.chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = 'ct';
        pieSeries.dataFields.category = 'label';
        pieSeries.slices.template.stroke = am4core.color('#fff');
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;

        // remove legend lines
        pieSeries.alignLabels = false;
        pieSeries.labels.template.text = '';

        this.ordereddata = orderBy(this.datas, 'ct', 'desc');

        const otherlabel = this.translateService.instant('labels.others');
        const other = { label: otherlabel, ct: 0 };
        for (let index = 0; index < this.ordereddata.length; index++) {
            const element = this.ordereddata[index];
            if (index > this.limit) {
                other.ct = other.ct + element.value;
            }
            this.total = this.total + element.value;
        }

        if (this.datas.length > this.limit) {
            this.chart.data = cloneDeep(this.ordereddata.slice(0, this.limit));
            this.chart.data.push(other);
        } else {
            this.chart.data = this.ordereddata;
        }

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
    }
}
