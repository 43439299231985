import 'rxjs/add/operator/filter';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import jwtDecode from 'jwt-decode';
import { Apiversion, AttachService, CrudService, CustomerAccess, CustomerAccessService, DialogService, isMobile, Page, Search, SharedDataService, UiConf, VersionService } from 'orv-ui-base-lib';
import { Subscription, timer } from 'rxjs';

import { environment } from '../../environments/environment';
import { VERSION } from '../../environments/version';
import { AuthService } from '../@core/auth/auth.service';
import { ResponsiveCommon } from '../@core/responsive.common';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends ResponsiveCommon implements OnInit, OnDestroy {

    timerRenew: Subscription;
    version = VERSION;
    currentdate: number;
    env: string;

    apiversion: Apiversion;

    alerts: number;
    loggeduser: CustomerAccess;
    uiconf: UiConf;
    loading: boolean = false;

    canShowOrvconnect: boolean;

    subscription: Subscription;

    constructor(private authService: AuthService,
        private versionService: VersionService,
        private sharedDataService: SharedDataService,
        private customerAccessService: CustomerAccessService,
        private crudServiceConf: CrudService<UiConf>,
        private router: Router,
        private attachService: AttachService,
        private titleService: Title,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private dialogService: DialogService) {
        super();

        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .map(() => this.activatedRoute)
            .map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            })
            .filter(route => route.outlet === 'primary')
            .subscribe(route => {
                console.log('Route:', route);
                const data = route.snapshot.data;
                if (data['title']) {
                    this.translateService.get(data['title']).subscribe(message => {
                        this.titleService.setTitle(message);
                    });
                } else {
                    this.titleService.setTitle(data['title']);
                }
            });

        this.subscription = this.sharedDataService.currentLoggedUser.subscribe(user => {
            this.loggeduser = user;
            if (this.loggeduser && this.loggeduser.guid) {
                this.loadUiConf(this.loggeduser.guid);
            }
            if (this.loggeduser && this.loggeduser.logo) {
                this.createLogoUrl();
            }
            this.canShowOrvconnect = this.loggeduser.catype == 'parent' && this.loggeduser.billingcustid !== null && this.loggeduser.ecomm === 1;
        });

    }


    ngOnInit() {
        // refresh token
        this.authService.renew().subscribe(() => {
            // schedule renew token
            this.scheduleRenew();
        });
        this.versionService.getApiversion().subscribe(data => {
            console.log('data:', data);
            this.apiversion = data;
        });
        this.env = environment.production ? 'prod' : 'dev';
        this.currentdate = new Date().getTime();

        this.sharedDataService.currentLoggedUser.subscribe(user => {
            if (user) {
                // check if "since" is localstorage
                const storage = localStorage.getItem('ORVCustSince');
                // create the now moment
                const now = Math.round(Date.now() / 1000);
                // set since among localstorage and now
                const since = storage ? parseInt(storage, 10) : 0;
                // get the alerts count and store the now in localstorage
                this.customerAccessService.getCustomerNotificationsSince(user.id, since).subscribe(count => {
                    this.alerts = count.ct || 0;
                    localStorage.setItem('ORVCustSince', JSON.parse(now.toString()));
                });
            }
        });


        if (isMobile.iOS()) {
            this.dialogService.openAlertModal({
                title: 'Looks like you are trying to access our app from a mobile browser. For an improved user experience we recommend using our app which you can download here or feel free to continue',
                message: 'Download the application from the App Store. <a class="text-link" href="https://apps.apple.com/app/city-logistics-customer/id1547399955" target="_blank"><u>Click here to download</u></a>',
                html: true,
            }).then(() => {
                // this.router.navigate(['/ticket/list']);
            });
        } else if (isMobile.Android()) {
            this.dialogService.openAlertModal({
                title: 'Looks like you are trying to access our app from a mobile browser. For an improved user experience we recommend using our app which you can download here or feel free to continue',
                message: 'Download the application from the Google Play Store. <a class="text-link" href="https://play.google.com/store/apps/details?id=it.elbuild.orv.orvcustomer.release.internal" target="_blank"><u>Click here to download</u></a>',
                html: true,
            }).then(() => {
                // this.router.navigate(['/ticket/list']);
            });
        }
    }

    loadUiConf(guid: number) {
        this.loading = true;
        const search = new Search('uiconf');
        search.addSimpleFilter('guid', guid);
        this.crudServiceConf.searchEntities(UiConf, 'uiconf', search, new Page(1)).subscribe(result => {
            this.uiconf = result[0];
            this.loading = false;
            this.sharedDataService.addCommonData('logo', this.uiconf.logobanner);
        }, error => {
            this.loading = false;
            console.log('error', error);
        });
    }

    createLogoUrl() {
        this.attachService.download(this.loggeduser.logo).subscribe(response => {
            const file = response;
            if (response) {
                const url = URL.createObjectURL(response);
                this.sharedDataService.addCommonData('logobanner', url);
                console.log('url', url);
            }
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }


    ngOnDestroy(): void {
        if (this.timerRenew) {
            this.timerRenew.unsubscribe();
        }
        if (this.subscription) { this.subscription.unsubscribe(); }
    }

    private scheduleRenew() {
        const token = jwtDecode(this.authService.getToken());
        // 30 min
        const d = new Date(token.exp * 1000);
        d.setUTCMinutes(d.getUTCMinutes() - 30);
        // d.setUTCSeconds(d.getUTCSeconds() - 50);
        this.timerRenew = timer(d).subscribe(() => {
            this.authService.renew().subscribe(() => {
                this.timerRenew.unsubscribe();
                this.scheduleRenew();
            });
        });
    }

}
