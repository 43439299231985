import { DeviceDetectorService } from 'ngx-device-detector';

import { ServiceLocator } from './data/locator.service';

export class ResponsiveCommon {

    $deviceService: DeviceDetectorService;

    responsive: boolean;
    mobile: boolean;

    allowedSections: string[];

    constructor() {
        this.$deviceService = ServiceLocator.injector.get(DeviceDetectorService);
        this.responsive = this.$deviceService.isMobile() || this.$deviceService.isTablet();
        this.mobile = this.$deviceService.isMobile();

        this.setAllowedSection();
    }

    setAllowedSection() {
        this.allowedSections = [];
    }
}
