import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OrvComponentsModule } from 'orv-ui-base-lib';
import { CommercialFeatureDirective } from './commercial-feature.directive';
import { AttachmentUploadComponent } from './attachment-upload/attachment-upload.component';
import { ListFiltersComponent } from './list-filters/list-filters.component';
import { NgbActiveModal, NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { AttachmentImageModalComponent } from './attachment-image-modal/attachment-image-modal.component';
import { LogoHeaderComponent } from './logo-header/logo-header.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        OrvComponentsModule,
        NgbTypeaheadModule,
        NgbModule
    ],
    exports: [
        OrvComponentsModule,
        CommercialFeatureDirective,
        AttachmentUploadComponent,
        PieChartComponent,
        ListFiltersComponent,
        AttachmentImageModalComponent,
        LogoHeaderComponent
    ],
    declarations: [
        CommercialFeatureDirective,
        AttachmentUploadComponent,
        PieChartComponent,
        ListFiltersComponent,
        AttachmentImageModalComponent,
        LogoHeaderComponent
    ],
    providers: [NgbActiveModal],
    entryComponents: []
})
export class CoreComponentsModule { }
