// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    commercial: false,
    websocketUrl: 'ws://orvapi.citylogistics.co.za:8888/orv/wsocket',
    firebase: {
        apiKey: 'AIzaSyCMHRcZum1LzOovZFwOr2Td87JNVFDNFRs',
        authDomain: 'orv-customer-app-1549309198652.firebaseapp.com',
        databaseURL: 'https://orv-customer-app-1549309198652.firebaseio.com',
        projectId: 'orv-customer-app-1549309198652',
        storageBucket: 'orv-customer-app-1549309198652.appspot.com',
        messagingSenderId: '1874669526',
        appId: '1:1874669526:web:d4ec738e5c4c8ef333fe77'
    }
};

