import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { OrvComponentsModule } from 'orv-ui-base-lib';

import { CoreComponentsModule } from '../core-components/core-components.module';
import { AuthenticationGuardService } from './auth-guard.service';
import { AuthenticationRoutes } from './auth.routing';
import { AuthService } from './auth.service';
import { JwtInterceptor } from './jwt.interceptor';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TokenLoginComponent } from './token-login/token-login.component';
import { TokenInterceptor } from './token.interceptor';

const SERVICES = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true
    },
    AuthService,
    AuthenticationGuardService,
    CookieService
];
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CoreComponentsModule,
        ReactiveFormsModule,
        OrvComponentsModule,
        RouterModule.forChild(AuthenticationRoutes)
    ],
    declarations: [
        LoginComponent,
        LogoutComponent,
        ResetPasswordComponent,
        TokenLoginComponent
    ],
    providers: [
        ...SERVICES,
    ],
})
export class AuthModule {
    static forRoot(): ModuleWithProviders {
        return <ModuleWithProviders>{
            ngModule: AuthModule,
            providers: [
                ...SERVICES,
            ],
        };
    }
}
